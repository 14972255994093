/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10) / 100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Client loaded in " + self.getLoadTime() + 's');
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  }
}


function StickyFooter($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  }
}


/*
 Ensure all external links load as new window/tabs:
 */


 function ExternalLinkHandler() {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings() {
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  this.bindPopovers = function () {
    $('[data-toggle="popover"]').popover();
  };

  this.hoverMenu = function(){
    $( ".listMenu li.list" ).each(function() {

      $(this).hover(function(){
        var list = $(this).attr('id');
         console.log(list);

     
       var listClass = $(list);
        var imageList = $('.menuItem.'+list);

       $('.menuItem').not(list).hide();
        imageList.show();
      });
      
      // console.log(listClass);
    });
  }
  // scroll page down
  this.bindScroll = function(){
    $('.scroll-down').click (function() {
      $('html, body').animate({scrollTop: $('#ok').offset().top - 120 }, 'slow');
      
      return false;
    });
    
    $('html, body').hide();
    
    if (window.location.hash) {
      setTimeout(function() {
        $('html, body').scrollTop(0).show();
        $('html, body').animate({
          scrollTop: $(window.location.hash).offset().top - 130
        }, 1000);
      }, 0);
    }
    else {
      $('html, body').show();
    }
    // $('html, body').animate({scrollTop: $('[scroll]').offset().top  }, 'slow');
  };

  this.bindReadMore = function(){
    $("#readmore").click(function(e) {
      e.preventDefault();

      var elem = $("#readmore").text();

      if (elem == "Read More") {
        $("#readmore").text("Read Less");
        $("#readmore").addClass('arrowUp');
        $("#readmore").removeClass('arrowDown');
        $(".intro-text").slideDown( "slow", function() {});
      } else {
        $("#readmore").text("Read More");
        $("#readmore").addClass('arrowDown');
        $("#readmore").removeClass('arrowUp');
        $(".intro-text").slideUp( "slow", function() {});
      }
    });
  }

  this.bindSlideDown = function(){
    $(".scroll-down").click(function() {
      $('html, body').animate({
        scrollTop: $(".entry.content").offset().top
      }, 2000);
    });
  };

  this.bindSlickCarousels = function () {

    $('[data-slick-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      fade: true
    });    


    $('[data-slick-carousel-home]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true
    });

    $('[data-slick-page-gallery]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true
    });

    $('[data-slick-carousel-landing]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true
    });

    $('[data-slick-blog-carousel]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: '<a href="#" class="slick-next"></a>',
      prevArrow: '<a href="#" class="slick-prev"></a>',
      autoplay: false,
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      ]
    });

    $('[data-testimonials-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: '<a href="#" class="slick-next"></a>',
      prevArrow: '<a href="#" class="slick-prev"></a>',
      autoplay: true,
      autoplaySpeed: 3000,
      fade: true
    });

    $('[data-slick-carousel-logos]').slick({
      dots: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true
    });

    $('[data-toggle="slick-nav"]').on('click', function (e) {
      e.preventDefault();
      var index = $(this).data('index');
      $('[data-slick-carousel-default]').slick('slickGoTo', index);
    });

  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
      shareHandler.appendGoogle();
      //shareHandler.appendLinkedIn();
      //shareHandler.appendPinterest();
    });
  };

  this.bindMagnificpopup = function(){
    $('[data-magnific-gallery]').magnificPopup({
     type: 'image',
     callbacks:
     {
      markupParse: function(template, values, item)
      {
        values.description = item.el.data('description');
      }
    },
    gallery:{
      enabled:true
    },

    image:
    {
      headerFit: true,
      captionFit: true,
      preserveHeaderAndCaptionWidth: false,
      markup:
      '<div class="mfp-figure">'+
      '<figure>'+
      '<header class="mfp-header">'+
      '<div class="mfp-top-bar">'+
      '<div class="mfp-title"></div>'+
      '<div class="mfp-close"></div>'+
      '<div class="mfp-decoration"></div>'+
      '</div>'+
      '</header>'+
      '<section class="mfp-content-container">'+
      '<div class="mfp-img"></div>'+
      '</section>'+
      '<footer class="mfp-footer">'+
      '<figcaption class="mfp-figcaption">'+
      '<div class="mfp-bottom-bar-container">'+
      '<div class="mfp-bottom-bar">'+
      '<div class="mfp-counter"></div>'+
      '<div class="mfp-description"></div>'+
      '<div class="mfp-decoration"></div>'+
      '</div>'+
      '</div>'+
      '</figcaption>'+
      '</footer>'+
      '</figure>'+
      '</div>',
    }
  });
  };

  this.bindMasonary = function () {

    $('[data-masonry-default]').masonry({
      itemSelector: '[data-masonry-default] > div',
      columnWidth: '.grid-sizer'
    });
  };

  this.bindSubmittingButtons = function () {
    $(document).on('submit', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });
  };

}

function Notifications () {
  var wrapper = $('#notifications-wrapper');
  var div = $('#notifications');
  var wrapperTop = wrapper.offset().top;

  this.bindOnScroll = function () {
    wrapper.height(wrapper.height());
    $(window).on('scroll', function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > wrapperTop) {
        div.addClass('fixed');
      } else {
        div.removeClass('fixed');
      }
    })
  };

  this.bindCloseButton = function () {
    $(document).on('click', '[data-toggle="remove"]', function (e) {
      e.preventDefault();
      var notification = $(this).closest('.notification');
      notification.fadeOut(250, function () {
        wrapper.height('auto');
        wrapper.height(wrapper.height());

      });
    });
  };

  this.init = function () {
    this.bindOnScroll();
    this.bindCloseButton();
  };
}



$(function () {

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler).addTargetAttribute($('body'));

  (new PageTimer).logToConsole();

  (new UIBindings).bindTooltips();

  (new UIBindings).bindPopovers();

  (new UIBindings).bindSlickCarousels();

  (new UIBindings).hoverMenu();

  (new UIBindings()).bindSlideDown();

  (new UIBindings()).bindReadMore();

  (new UIBindings).bindSharing();

  (new UIBindings).bindMasonary();

  (new UIBindings).bindMagnificpopup();

  (new UIBindings).bindSubmittingButtons();

  (new Notifications).init();
});
